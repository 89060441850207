<template>
  <section class="bg-secondary decoration-1 pb-7">
    <!-- NAVBAR -->
    <div class="navbar py-3">
      <div
        class="container d-flex justify-content-between align-items-stretch flex-column flex-md-row"
      >
        <div class="brand">
          <router-link to="/">
            <img src="/svgs/logo.svg" alt="" />
          </router-link>
        </div>

        <router-link
          to="barcelona"
          class="btn btn-outline-primary mt-4 mt-md-0 text-center"
        >
          <i class="fa-solid fa-location-dot mr-2"></i>
          Barcelona Campus
        </router-link>
      </div>
    </div>
    <!-- /NAVBAR -->

    <div class="container py-6">
      <div class="row">
        <div class="col-md-6">
          <h1>
            <span class="text-white">
              You Irish folks <br />
              want to study <br />
              Spanish and get
            </span>
            <br />
            <span class="text-primary">
              a work contract <br />
              while living in <br />
              Barcelona?
            </span>
          </h1>
        </div>
        <div class="col-md-6 pt-4 pt-md-0">
          <div class="video-container">
            <video
              src="/imgs/landing_0816/VIDEO.mp4"
              @click="stopVideo"
              ref="video"
            ></video>
            <div
              class="img-container"
              style="background-image: url('/imgs/landing_0816/VIDEO.png')"
              @click="playVideo"
              :class="{ hidden: playingVideo }"
            />
          </div>
        </div>
      </div>
    </div>
  </section>

  <div class="container offset-y-4">
    <div class="card card-white text-center">
      <div class="card-body p-4">
        <h4 class="mt-4 text-dark">
          Send us your information and
          <span class="text-secondary">
            get contacted in your own language
          </span>
        </h4>

        <div class="pt-4 px-md-7" id="form" />
      </div>
    </div>
  </div>

  <section class="text-center py-6">
    <i class="isologo mb-3"></i>
    <h3>
      Live in one of the most famous cities in the world while studying Spanish
    </h3>

    <swiper
      class="mt-5 mb-4"
      :modules="modules"
      :pagination="{ clickable: false }"
      :breakpoints="swiperBreakpoints"
      loop
      autoplay
      centeredSlides
      style="--pagination-bottom: -35px"
    >
      <swiper-slide v-for="n of slides" :key="n">
        <div class="card card-secondary">
          <div
            class="img-container minh-5"
            :style="`background-image: url('${n.img}')`"
          />
          <p class="mt-3">{{ n.title }}</p>
        </div>
      </swiper-slide>
    </swiper>
  </section>

  <section class="bg-secondary text-white text-center py-6">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-md-4 px-5">
          <h2>This Job</h2>
          <p>
            is from <b>Monday to Friday</b> between
            <b>09:00 am and 15:00 pm</b> and is between
            <b>20 - 30 hours per week</b>
          </p>
        </div>
        <div class="col-md-4 py-5 py-md-0 px-5">
          <h2>Semi-intesive course</h2>
          <p>
            so that you can make it <b>compatible with your job.</b> From
            <b>Monday to Friday, after work.</b> The course is
            <b>10 hours per week</b>
          </p>
        </div>
        <div class="col-md-4">
          <div
            class="bg-primary circle-shape maxw-2 d-flex justify-content-center align-items-center mx-auto"
          >
            <h2 class="lh-1">
              <span class="d-block">Up to </span>
              <span class="fs-150 d-block mt-2">€ 900</span>
              <span class="fs-70 d-block">per month</span>
            </h2>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="bg-primary decoration-4">
    <div class="row">
      <div class="col-md-4">
        <div
          class="img-container h-100 minh-8"
          style="background-image: url('/imgs/landing_0816/01.png')"
        />
      </div>
      <div class="col-md-8 d-flex pl-md-6 align-items-center">
        <div class="maxw-8 p-5">
          <h3 class="text-secondary">What’s the craic?</h3>

          <p class="text-white">
            <b>Study Spanish</b> in the afternoon from monday to friday with
            native speakers teachers, and <b>work in the mornings</b> with our
            partners as a language assistant.
          </p>

          <div class="card card-dark overflow-visible mt-5">
            <div class="card-body">
              <div class="img-container img-container-circle ireland-flag" />
              <p class="mx-4 my-3">
                Conducting, linguistic, intercultural, and recreational
                activities to bring the Irish culture closer to pupils and
                promote the motivation and interest of students over the
                language and culture.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="bg-tertiary decoration-5">
    <div class="row">
      <div
        class="col-md-8 d-flex pl-md-6 align-items-center order-2 order-md-1"
      >
        <div class="maxw-8 ml-auto p-5">
          <h3 class="text-secondary">Why Barcelona?</h3>

          <p class="text-white">
            With <b>300 sunny days in a year,</b> gorgeous beaches, incredible
            nightlife and a lot of culture around, if you are looking for a
            unique experience, Barcelona is the right place for you!
          </p>

          <a href="#form" class="btn btn-white d-inline-block">
            I WANT TO BE A NLC STUDENT
          </a>
        </div>
      </div>
      <div class="col-md-4 order-1 order-md-2">
        <div
          class="img-container h-100 minh-8"
          style="background-image: url('/imgs/landing_0816/02.png')"
        />
      </div>
    </div>
  </section>

  <section class="bg-secondary decoration-6">
    <div class="row">
      <div class="col-md-4">
        <div
          class="img-container h-100 minh-8"
          style="background-image: url('/imgs/landing_0816/03.png')"
        />
      </div>
      <div class="col-md-8 d-flex pl-md-4 pl-lg-6 align-items-center">
        <div class="maxw-8 p-5 p-lg-0">
          <h3 class="text-primary">Accommodation</h3>

          <p class="text-white">
            We have partnership with <b>accommodation companies</b> all around
            Barcelona, and we can help you to find the best fit for you.
          </p>
        </div>
      </div>
    </div>
  </section>

  <section class="text-center py-6">
    <i class="isologo mb-4"></i>
    <h3 class="text-primary">Starting Day</h3>

    <p class="maxw-5 mx-auto">
      Our deal is valid for classes starting from the second week of
      <b>September until the second week of October.</b>
    </p>

    <swiper
      class="mt-5 mb-4"
      :modules="modules"
      :pagination="{ clickable: false }"
      :breakpoints="swiperBreakpoints2"
      loop
      autoplay
      centeredSlides
      style="--pagination-bottom: -35px"
    >
      <swiper-slide v-for="n of Array.from(Array(3).keys())" :key="n">
        <div
          class="img-container img-container-rounded minh-5"
          :style="`background-image: url('/imgs/landing_0816/0${n + 4}.png')`"
        />
      </swiper-slide>
    </swiper>

    <a class="d-inline-block btn btn-primary mt-6" href="#form">
      FIND 500MILLON MORE OPPORTUNITIES BY SPEAKING SPANISH
    </a>
  </section>
</template>

<script>
import { Pagination, Navigation, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import "swiper/swiper-bundle.min.css";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data: () => ({
    modules: [Pagination, Navigation, Autoplay],
    swiperBreakpoints: {
      0: { slidesPerView: 2, spaceBetween: 20 },
      900: { slidesPerView: 4, spaceBetween: 20 },
      1200: { slidesPerView: 6, spaceBetween: 20 },
    },
    swiperBreakpoints2: {
      0: { slidesPerView: 2, spaceBetween: 20 },
      900: { slidesPerView: 4, spaceBetween: 20 },
    },
    slides: [
      {
        img: "/imgs/landing_0816/slide_1.png",
        title: "Mercado la Rampla",
      },
      {
        img: "/imgs/landing_0816/slide_2.png",
        title: "Barceloneta Beach",
      },
      {
        img: "/imgs/landing_0816/slide_3.png",
        title: "La Sagrada Familia",
      },
      {
        img: "/imgs/landing_0816/slide_4.png",
        title: "Park Guell",
      },
      {
        img: "/imgs/landing_0816/slide_5.png",
        title: "Mercado la Rampla",
      },
      {
        img: "/imgs/landing_0816/slide_6.png",
        title: "Casa Batlló",
      },
      {
        img: "/imgs/landing_0816/slide_7.png",
        title: "Camp Nou",
      },
    ],
    playingVideo: false,
  }),
  mounted() {},
  beforeUnmount() {},
  methods: {
    playVideo() {
      this.playingVideo = true;
      this.$refs.video.play();
    },
    stopVideo() {
      this.playingVideo = false;
      this.$refs.video.pause();
    },
    goToForm() {
      window.location = window.location + "#form";
    },
  },
};
</script>

<style lang="scss" scoped>
h1 {
  font-size: clamp(2.5em, 3vw, 3.2em);
}
input {
  background: white;
  color: var(--color-medium);
}
.offset-y-4 {
  margin-top: -6em;
  z-index: 1;
  position: relative;
}
.ireland-flag {
  background-image: url("/imgs/landing_0816/ireland-flag.png");
  --min-radius: 0;
  width: 40px;
  height: 40px;
  position: absolute;
  top: -12px;
  left: -12px;
}
.video-container {
  overflow: hidden;
  border-radius: 1.5em;
  position: relative;
  cursor: pointer;

  .img-container {
    position: absolute;
    inset: 0;
    transition: 0.5s ease;
    background-size: 100%;

    &:hover {
      transition: 0.5s ease;
      background-size: 110%;
    }

    &.hidden {
      opacity: 0;
      transition: 0.5s ease;
      pointer-events: none;
    }
  }

  video {
    width: 100%;
    margin-bottom: -6px;
  }
}
.circle-shape {
  aspect-ratio: 1;
  border-radius: 50%;
}
.clear-both {
  clear: both;
}
</style>
